import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="NASSCOM Pune Head Explores Synergy with Elfonze!"
        paragraph="Innovation meets collaboration: Elfonze Technologies and NASSCOM Pune unite to redefine Pune's tech landscape!"
      />
      <h1 className="d-none">NASSCOM Pune Head Explores Synergy with Elfonze!</h1>
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/elf_insider/elf-nsc-meet-n-greet.webp" alt="Elfonze_Nasscom_Collaboration" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <h2>
                            - The Power of Collaboration
                          </h2>
                          <p>
                          Elfonze Technologies Pune Experience Centre recently welcomed Mr. Vrishabh Kapoor, Head of NASSCOM Pune's Membership Services & Member Engagement of GCC’s, alongside Neha Gupta, Membership Services & Member Engagement of IT organizations, on April 10, 2024. This marked a pivotal moment for both entities, emphasizing a shared commitment to collaboration and innovation.
                          </p>
                          {/* <h2>- Exploring the Possibilities </h2> */}
                          <p>
                          In an engaging exchange, Mr. Kapoor, along with Elfonze Technologies' leadership trio—Avinash Khandelwal, Sandeep Mulekar, and Nitin Nagargoje—delved into discussions focusing on partnership cultivation within Pune's dynamic technology ecosystem. His insights not only illuminated Elfonze's trajectory but also sparked inspiration, reinforcing the company's dedication to excellence.
                          </p>
                          {/* <h2>- The Role of Elfonze Technologies  </h2> */}
                          <p>
                          The meeting yielded significant takeaways, including recognition for Elfonze HTD services, encouragement to participate in industry events, highlighting industry interests in auto and retail sectors, forging a strategic partnership with Snowflake for data and analytics, and organizing domain/tech seminars as per NASSCOM's directive.
                          </p>
                          {/* <h2>- Benefits of Connected Supply Chains  </h2> */}
                          <p>
                          Elfonze Technologies extends sincere appreciation to Mr. Kapoor and Ms. Gupta for their esteemed presence. With a shared vision, the collaboration aims to drive innovation, foster growth, and achieve collective success.
                          </p>

                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              <a href="#0">#NASSCOM</a>,
                              <a href="#0">#ElfonzeTechnologies</a>,
                              <a href="#0">#Collaboration</a>,
                              <a href="#0">#Innovation</a>,
                              <a href="#0">#TechIndustryLeadership</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies & NASSCOM Pune: Igniting Innovation & Collaboration</title>
      <meta key="description" name="description"
        content="Explore the dynamic collaboration between Elfonze Technologies and NASSCOM Pune, as they join forces to drive innovation and growth in Pune's tech landscape. Discover insights from Mr. Vrishabh Kapoor and Elfonze's leadership trio, highlighting strategic partnerships, industry recognition, and a shared commitment to excellence." />
    </>
  )
}

export default BlogDetailsDark;
